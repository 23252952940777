
<template>
    <div>
        <v-row class="mx-auto mb-4">

            <v-col cols="6">
                <v-img src="./images/group_photo.jpg" max-width="500" max-height="500" class="grey lighten-2"></v-img>
            </v-col>
            <!-- <v-col cols="6">
                <div class="twitter-feed">
                    <a class="twitter-timeline" data-width="400" data-height="600" data-theme="light"
                        href="https://twitter.com/mitrokat">Tweets by TwitterAccount</a>
                </div>
            </v-col> -->
            <v-col cols="12" md="6">
                <v-card margin="10px" elevation="0" style="max-width: 700px;" class="mx-auto mb-4">
                    <!-- <v-card margin="10px" color="#E8f5ef" style="max-width: 700px;" class="mx-auto mb-4"> -->
                    <v-card-text style="font-size: 15px;">
                        <div class="text--primary">
                            The Cybersecurity and Applied Cryptography group is a research group at the School of
                            Computer
                            Science at the University of St. Gallen led by Prof. Katerina Mitrokotsa, while a branch is
                            located at Chalmers University of Technology.
                            <br>
                            <br>

                            Our research interests are centered around information security and applied cryptography,
                            with
                            the larger goal of safeguarding communications and providing strong privacy guarantees. We
                            are
                            active in several areas, a subset of which include:
                            <br>

                            <ul class="custom-bullet-list">
                                <li>Functional encryption</li>
                                <li>Verifiable computation</li>
                                <li>Secure, private and distributed aggregation</li>
                                <li>Secure multi-party computation</li>
                                <li>Privacy-preserving biometric authentication</li>
                                <li>Proximity-based authentication</li>
                            </ul>

                            Take a look at our research and publications pages for a full list.
                           
                            <br>
                            <br>
                            Our flagship course Cybersecurity is offered in the Fall semester. We also offer a research
                            seminar in the Spring and Fall semester, and a course on Cryptograhy in the Spring semester.
                            Check our courses page for more information.
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
        <div>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
        </div>

        <v-card class="mx-auto">
            <v-card-title>
                <h3 class="text-h6 font-weight-regular text-left grow subtitle">
                    News
                </h3>
            </v-card-title>

            <!-- <v-card-text class='news-content' style="font-size: 15px;"> -->
            <v-card-text class='news-content'>
                <div v-for="(map, index) in recentNews" :key="index">
                    <div v-for="(yearContent, year) in map" :key="year">
                        <h3>{{ year }}</h3>
                        <ul>
                            <li v-for="(items, month) in yearContent" :key="month">
                                <h3>{{ month }}</h3>
                                <ul v-for="final in items" :key="final.title">
                                    <li>
                                        <strong>{{ final.title }}</strong>
                                        <p v-html="final.description"></p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-card class="mx-auto mt-16" margin="5px">
            <v-card-title>

                <h3 class="text-h6 font-weight-regular  text-left grow subtitle">
                    Recent Publications
                </h3>

            </v-card-title>
            <v-list three-line>
                <template v-for="(journal) in recentPub">
                    <v-list-item :key="journal.id">
                        <!-- <v-col cols="3" class="pl-10">
                            <iframe height="170px" width="120px" frameborder="0" :src="item.paperLink" ></iframe>
                    </v-col> -->
                        <v-col>
                            <v-list-item-content>
                                <div class="d-flex justify-start pr-3"><v-list-item-title v-html="journal.name"
                                        class="font-weight-medium"></v-list-item-title>
                                </div>
                                <v-list-item-title v-html="journal.authors"
                                    style="white-space: normal"><strong>Authors:</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="journal.journal"
                                    class="text-wrap"><strong>Journal:</strong></v-list-item-subtitle>
                                <v-list-item-subtitle class="text-wrap">
                                    <span v-if="journal.paperLink" class="mr-2">
                                        <a :href="journal.paperLink" target="_blank"><i
                                                class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                                    </span>
                                    <span v-if="journal.pdfLink" class="mr-2">
                                        <a :href="journal.pdfLink" target="_blank"><i
                                                class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                                    </span>
                                    <span v-if="journal.preprintLink" class="mr-2">
                                        <a :href="journal.preprintLink" target="_blank"><i
                                                class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                                    </span>
                                    <span v-if="journal.codeLink" class="mr-2">
                                        <a :href="journal.codeLink" target="_blank"><i
                                                class="fa-solid fa-code"></i>&nbsp;Code</a>
                                    </span>
                                    <span v-if="journal.slideLink" class="mr-2">
                                        <a :href="journal.slideLink" target="_blank"><i
                                                class="fa-solid fa-display"></i>&nbsp;Slides</a>
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-col>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </div>
</template>



<style>
/* Your CSS styles go here */
.custom-bullet-list {
    list-style-type: disc; /* You can change the bullet style */
    margin-left: 20px; /* Indentation of the list */
}

.custom-bullet-list li {
    margin-bottom: 5px; /* Space between list items */
}

.scrollable-card-text {
    height: 400px;
    overflow-y: auto;
}

.news-content ul {
    padding-left: 1em;
}

.news-content li {
    list-style-type: disc;
}

.news-content h3 {
    font-size: 1.5em;
    font-weight: normal;
    /* Adjust size for year headings */
}

.news-content li h3 {
    font-size: 1.2em;
    /* Slightly smaller for month headings to create hierarchy */
}

 .news-content ul li strong {
    font-size: 1.1em;
    font-weight: bold;
}

 .news-content ul li p {
     font-size: 1.0em;
 }

.news-date {
    font-weight: bold;
}
</style>


<script>
import NEWS from './json/news.json';
import PUBLICATIONS from './json/publications.json';
export default {
    data: () => ({
        recentPub: [],
        recentNews: []
    }),
    beforeMount() {
        this.recentPub = PUBLICATIONS.slice(0, 9);
        this.recentNews = NEWS;
    }
}
</script>



